import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { useViewport } from "../../utils/useViewport";

export const getTourDates = graphql`
  query {
    allContentfulShow(sort: { order: ASC, fields: date }) {
      edges {
        node {
          city
          date(formatString: "l")
          linkToPurchase
          soldOut
          venue
        }
      }
    }
  }
`;

const TourContainer = styled.div`
  padding: ${({ theme }) => theme.layout.gutter}px;
  color: ${({ theme }) => theme.colors.text};
  font-family: ${({ theme }) => theme.type.fontFamily};
`;

const Show = styled.div<{ soldOut: boolean; bp: string }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  margin-bottom: 32px;
  line-height: 18px;
  font-size: 20px;
  color: ${({ theme, soldOut }) => (soldOut ? "#BDBDBD" : theme.colors.text)};
  .left,
  .right {
    width: 50%;
    display: flex;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .right .column {
    text-align: right;
  }
  .column {
    width: 250px;
    padding-right: 10px;

    @media screen and (max-width: 768px) {
      margin-bottom: 16px;
      width: auto;
    }
  }

  .date,
  .city {
    font-weight: 800;
  }
`;

const Tour = ({ data }) => {
  const shows = data.allContentfulShow.edges.filter(edge => edge.node.venue !== "PLACEHOLDER SHOW DO NOT UNPUBLISH") 
  const { currentBreakPoint } = useViewport();
  return (
    <TourContainer>
      {shows.length ?
        shows.map((showEdge, index) => {
          const { date, venue, city, linkToPurchase, soldOut } = showEdge.node;
          return (
            <Show
              soldOut={soldOut}
              key={`${city}-${index}`}
              bp={currentBreakPoint}
            >
              <div className="left">
                <div className="column city">{city}</div>
                <div className="column venue">{venue} </div>
              </div>
              <div className="right">
                <div className="column date">{date}</div>
                <div className="column">
                  <a href={linkToPurchase}>Tickets</a>
                </div>
              </div>
            </Show>
          );
        }) : <p>There are no shows scheduled at the moment.</p>}
    </TourContainer>
  );
};

export default Tour;
